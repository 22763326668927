import { render, staticRenderFns } from "./tabData.vue?vue&type=template&id=40b2a480&scoped=true&"
import script from "./tabData.vue?vue&type=script&lang=js&"
export * from "./tabData.vue?vue&type=script&lang=js&"
import style0 from "./tabData.vue?vue&type=style&index=0&id=40b2a480&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "40b2a480",
  null
  
)

export default component.exports