<template>
    <div class="tab-content">
        <div class="module-big">
            <div class="title">关系分析</div>
            <div class="content flex">
                <div class="info-item flex-column" style="border-right:1px solid #F2F3F5"  @click="toPage('bjfx')">
                    <div class="info-item-title">投资关系分析</div>
                    <div>
                        <el-tag type="warning" size="mini">投资方 {{label.investCount}} 个</el-tag>
                        <el-tag type="warning" size="mini">对外投资企业 {{label.investOutCount}} 个</el-tag>
                    </div>
                </div>
                <div class="info-item flex-column"  @click="toPage('sgdw')">
                    <div class="info-item-title">关联施工单位分析</div>
                    <div>
                        <el-tag type="" size="mini">关联关系个数 {{label.executorCount}} 个</el-tag>
                        <el-tag type="" size="mini">关联合同金额(万元)：{{label.contractCostAmount}}</el-tag>
                    </div>
                </div>
                <!-- <div class="info-item flex-column">
                    <div class="info-item-title">合作关系分析</div>
                    <div>
                        <el-tag type="" size="mini">合作关系数 {{label.cooperationCount}} 个</el-tag>
                    </div>
                </div> -->
            </div>
        </div>
        <div class="module-big">
            <div class="title">风险分析</div>
            <div class="content flex">
                <div class="info-item flex-column" style="border-right:1px solid #F2F3F5"  @click="toPage('flfx')">
                    <div class="info-item-title">法律风险</div>
                    <div class="flex-column">
                        <el-tag type="warning" size="mini">建设单位与施工单位的法律纠纷 {{label.legalUnitCount}} 个</el-tag>
                        <el-tag type="warning" size="mini">建设单位的其他法律纠纷 {{label.legalProjectCount}} 个</el-tag>
                    </div>
                </div>
                <div class="info-item flex-column" @click="toPage('yqfx')">
                    <div class="info-item-title">舆情风险</div>
                    <div>
                        <el-tag type="warning" size="mini">舆情风险条数 {{label.opinionCount}} 个</el-tag>
                    </div>
                </div>
            </div>
        </div>
        <div class="module-small-box">
            <!-- <div class="modle-small" @click="toPage('sgdw')">
                <div class="title">关联施工单位分析</div>
                <div class="content flex-column">
                    <el-tag type="" size="mini">关联关系个数 {{label.executorCount}} 个</el-tag>
                    <el-tag type="" size="mini">关联合同金额(万元)：{{label.contractCostAmount}}</el-tag>
                </div>
            </div> -->
            <!-- <div class="modle-small" @click="toPage('history')">
                <div class="title">历史建设项目</div>
                <div class="content flex-column">
                    <el-tag type="" size="mini">历史建设项目 {{label.performanceCount}} 个</el-tag>
                </div>
            </div> -->
            <!-- <div class="modle-small" @click="toPage('land')">
                <div class="title">历史拿地信息</div>
                <div class="content flex-column">
                    <el-tag type="" size="mini">土地交易信息 </el-tag>
                </div>
            </div> -->
            
        </div>
        <div class="module-small-box">
            <div class="modle-small" @click="toPage('portrait')">
                <div class="title">项目画像</div>
                <div class="content flex-column">
                    <div>
                        <el-tag type="" size="mini">基本信息</el-tag>
                        <el-tag type="" size="mini">项目标签</el-tag>
                    </div>
                </div>
            </div>
            <div class="modle-small" @click="toPage('regional')">
                <div class="title">区域分析</div>
                <div class="content flex-column">
                    <div>
                        <el-tag type="" size="mini">人口与面积</el-tag>
                        <el-tag type="" size="mini">区域与行业</el-tag>
                    </div>
                </div>
            </div>
            
        </div>
        <div class="module-small-box">
            <div class="modle-small" @click="toPage('zbqk')">
                <div class="title">周边情况</div>
                <div class="content flex-column">
                    <div>
                        <el-tag type="" size="mini">小区</el-tag>
                        <el-tag type="" size="mini">交通</el-tag>
                        <el-tag type="" size="mini">商业</el-tag>
                        <el-tag type="" size="mini">医院</el-tag>
                        <el-tag type="" size="mini">学校</el-tag>
                    </div>
                </div>
            </div>
            <div class="modle-small" @click="toPage('sfal')">
                <div class="title">税负案例</div>
                <div class="content flex-column">
                    <el-tag type="info" size="mini">敬请期待</el-tag>
                </div>
            </div>
        </div>
        <div class="module-small-box">
             <!-- <div class="modle-small" @click="toPage('zckj')">
                <div class="title">政策口径</div>
                <div class="content flex-column">
                    <el-tag type="info" size="mini">敬请期待</el-tag>
                </div>
            </div> -->
            <!-- <div class="modle-small" @click="toPage('hyfx')">
                <div class="title">行业分析</div>
                <div class="content flex-column">
                    <el-tag type="info" size="mini">敬请期待</el-tag>
                </div>
            </div> -->
        </div>
        <div class="module-small-box">
            <div class="modle-small" @click="toPage('ywcb')">
                <div class="title">成本分析</div>
                <div class="content flex-column">
                    <el-tag type="info" size="mini">敬请期待</el-tag>
                </div>
            </div>
            <div class="modle-small" @click="toPage('sgfxx')">
                <div class="title">施工方信息</div>
                <div class="content flex-column">
                    <el-tag type="info" size="mini">敬请期待</el-tag>
                </div>
            </div>
           
        </div>

    </div>
</template>

<script>
import {getStore, setStore,getLocalStore} from "@/util/store";
import {mapMutations} from "vuex";

export default {
    data() {
        return {
            label: {},
            active: '',
        };
    },
    created() {

    },
    mounted() {
        let item = document.querySelectorAll('.flex-column')
        item.forEach(item=>{
            item.addEventListener('mouseover',function(){
                item.style.backgroundColor = '#F9F9F9'
            })
            item.addEventListener('mouseout',function(){
                item.style.backgroundColor = '#FFFFFF'
            })
        })
        setTimeout(() => {
            this.getLabel() // 获取数据
        }, 600)
    },
    computed: {},
    methods: {
        ...mapMutations(["addPath", "addMenuList"]),
        toPage(index) {
            if (index == "bjfx") {
                this.addPath("建设方分析");
                this.addMenuList("/analysisDetails");
                setStore("currentMenu", "/analysisDetails");
                this.$router.push({path: "/analysisDetails"});
            } else if (index == 'sgdw') {
                if (this.label.executorCount == 0){
                    this.$message.warning('暂无数据')
                }else {
                    this.addPath("与施工单位关联关系");
                    this.addMenuList("/glsgdwfx");
                    setStore("currentMenu", "/glsgdwfx");
                    this.$router.push({path: "/glsgdwfx"});
                }

            } else if (index == 'flfx') {
                if (this.label.legalUnitCount == 0 && this.label.legalProjectCount == 0){
                    this.$message.warning('暂无数据')
                }else {
                    this.addPath("法律风险评估");
                    this.addMenuList("/flfxpg");
                    setStore("currentMenu", "/flfxpg");
                    this.$router.push({path: "/flfxpg",query:{activeName:'cu'}});
                }
            }
           else if (index == 'zbqk') {
                let info = getStore('thisProject');
                if (info.latitude == '' && info.longitude == '') {
                    this.$message.error('未检测到相应的项目坐标：该项目地址未上传')
                    return
                }
                if (info.longitude > 70 && info.longitude < 135 && info.latitude > 20 && info.latitude < 55) {
                    this.addPath("周边情况");
                    this.addMenuList("/mapAround");
                    setStore("currentMenu", "/mapAround");
                    this.$router.push({path: "/mapAround"});
                } else {
                    this.$message.error('该项目填写的经纬度坐标不在中国版图范围内，请在项目信息中修改后再使用');
                }
            }
            else if (index == 'history') {
                if (this.label.performanceCount == 0){
                    this.$message.warning('暂无数据')
                }else {
                    this.addPath("历史建设项目");
                    this.addMenuList("/hisrotyProject");
                    setStore("currentMenu", "/hisrotyProject");
                    this.$router.push({path: "/hisrotyProject"});
                }

            }else if (index == 'land') {
                if (this.label.performanceCount == 0){
                    this.$message.warning('暂无数据')
                }else {
                    this.addPath("历史拿地信息");
                    this.addMenuList("/landTransaction");
                    setStore("currentMenu", "/landTransaction");
                    this.$router.push({path: "/landTransaction"});
                }

            }else if (index == 'portrait') {
                if (!this.label.isProjectImageShow){
                    this.$message.warning('暂无数据')
                }else {
                    this.addPath("项目画像");
                    this.addMenuList("/portraitProject");
                    setStore("currentMenu", "/portraitProject");
                    this.$router.push({path: "/portraitProject"});
                }

            }else if (index == 'regional') {
                let info = getStore('thisProject');
                let proCode = info.administrativeCode.slice(0,2);
                if (proCode != 13){
                    this.$message.warning('暂无数据')
                }else {
                    this.addPath("区域分析");
                    this.addMenuList("/regional");
                    setStore("currentMenu", "/regional");
                    this.$router.push({path: "/regional"});
                }

            } else if (index == 'yqfx') {
                if (this.label.opinionCount == 0){
                    this.$message.warning('暂无数据')
                }else {
                    this.addPath("舆情风险");
                    this.addMenuList("/flfxpg");
                    setStore("currentMenu", "/flfxpg");
                    this.$router.push({path: "/flfxpg",query:{activeName:'yuqing'}});
                }
            } else if (index == 'zcfg') {
                // if (this.label.opinionCount == 0){
                //     this.$message.warning('暂无数据')
                // }else {
                    this.addPath("政策法规");
                    this.addMenuList("/policiesRegulation");
                    setStore("currentMenu", "/policiesRegulation");
                    this.$router.push({path: "/policiesRegulation"});
                // }
            } else if (index == 'zckj') {
                // if (this.label.opinionCount == 0){
                //     this.$message.warning('暂无数据')
                // }else {
                    this.addPath("政策口径");
                    this.addMenuList("/policyCaliber");
                    setStore("currentMenu", "/policyCaliber");
                    this.$router.push({path: "/policyCaliber"});
                // }
            } else if (index == 'hyfx') {
                // if (this.label.opinionCount == 0){
                //     this.$message.warning('暂无数据')
                // }else {
                    this.addPath("行业分析");
                    this.addMenuList("/industryAnalysis");
                    setStore("currentMenu", "/industryAnalysis");
                    this.$router.push({path: "/industryAnalysis"});
                // }
            } else if (index == 'ywcb') {
                // if (this.label.opinionCount == 0){
                //     this.$message.warning('暂无数据')
                // }else {
                    this.addPath("成本分析");
                    this.addMenuList("/businessCost");
                    setStore("currentMenu", "/businessCost");
                    this.$router.push({path: "/businessCost"});
                // }
            }
             else if (index == 'sgfxx') {
                // if (this.label.opinionCount == 0){
                //     this.$message.warning('暂无数据')
                // }else {
                    this.addPath("施工方信息");
                    this.addMenuList("/construction");
                    setStore("currentMenu", "/construction");
                    this.$router.push({path: "/construction"});
                // }
            }
            else if (index == 'sfal') {
                // if (this.label.opinionCount == 0){
                //     this.$message.warning('暂无数据')
                // }else {
                    this.addPath("税负案例");
                    this.addMenuList("/taxCase");
                    setStore("currentMenu", "/taxCase");
                    this.$router.push({path: "/taxCase"});
                // }
            }

        },
        getLabel() {
            const companyName = getStore('thisProject').taxpayerName
            const id = getStore('thisProject').id
            const userId = getLocalStore('userinfo').id
            this.axios.request({
                method: "get",
                url: '/dms/label',
                 
                params: {
                    companyName: companyName,
                    projectId: id,
                    userId: userId
                },
            }).then((res) => {
                if (res.data.status == "failed") {
                    if(res.data.msg=='tokenMissing'||res.data.msg=='tokenExpired'||res.data.msg=='tokenIllegal'){
                       
                    }else{
                        this.$message.error(res.data.msg);
                    }
                } else if (res.data.status == "success") {
                    this.label = res.data.data;
                }
            })
        }
    },
};
</script>

<style scoped lang="less">
.tab-content {
    height: 100%;

    .module-big {
        background-color: #ffffff;
        cursor: pointer;
        margin-bottom: 15px;
        border-radius: 3px;
    }

    .content {
        height: 100px;
        box-sizing: border-box;
        padding: 10px;
    }

    .flex {
        display: flex;
    }

    .flex-column {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }

    .info-item {
        flex: 1;

        .info-item-title {
            font-size: 14px;
            margin-bottom: 5px;
        }
    }

    .el-tag {
        margin: 5px;

        div {
            display: inline-block;
        }
    }

    .title {
        font-size: 16px;
        padding-left: 10px;
        line-height: 40px;
        height: 40px;
        font-weight: bold;
        border-bottom: 1px solid #f2f3f5;
    }

    .module-small-box {
        display: flex;
        justify-content: space-between;
        margin-bottom: 15px;
    }

    .modle-small {
        width: 396px;
        background-color: #ffffff;
        cursor: pointer;
        border-radius: 3px;
    }
}
</style>
